.image-carousel .carousel {
    display: grid;
}
.image-carousel .slide {
    justify-content: center;
    display: flex;
}
.image-carousel .slider .selected img {
    width: auto;
}
.image-carousel .carousel .thumb img {
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.image-carousel .carousel .thumbs .selected {
    border: none;
    border-bottom: 2px solid #FB4747;
}
.image-carousel .carousel .thumb {
    height: 60px;
    width: 90px !important;
    border: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}
.carousel .thumbs {
    display: flex;
    justify-content: center;
}
.carousel .control-next.control-arrow:before, .carousel .control-prev.control-arrow:before {
    border: none !important;
    background: url(../../../assets/images/next.svg) no-repeat;
    width: 45px;
    height: 45px;
    background-size: 100%;
}
.carousel .control-prev.control-arrow:before {
    background: url(../../../assets/images/pre.svg) no-repeat;
    background-size: 100%;
}
.carousel.carousel-slider .control-arrow {
    opacity: 1;
    background: transparent !important;
}