.carousel .thumbs-wrapper {
    text-align: center;
}
.carousel .thumbs {
    padding: 0;   
}
@media screen and (min-width: 900px) {
    .mobile-slider {
        display: none;
    }
}