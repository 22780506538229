.background-video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: -1;
    opacity: 0.3;
    background-color: rgba(0, 0, 0, 0.3);
    margin-top: 88px;
}
  
.background-video {
    width: 100%;
    height: auto;
}

/* @media (max-width: 1250px) {
    .background-video {
        height: auto;
        object-fit: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
} */
  