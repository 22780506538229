.background-video-frame-container {
    left: 0;
    /* width: 100%; */
    overflow: hidden;
    z-index: -1;
    /* opacity: 0.3; */
    vertical-align: top;
    margin-top: 30px;
    border: 7px solid #fff;
    border-radius: 4px;
}
  
.background-video-frame {
    width: 100%;
    height: 100%;
}